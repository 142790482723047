import React, { useState } from 'react'

const Achivements = () => {
  const menuItems = [
    { id: 1, label: "Our Experience and Expertise" },
    { id: 2, label: "Our Methodology" },
    { id: 3, label: "Flexibility and Scalability" },
    { id: 4, label: "Cost and Budget considerations" },
    { id: 5, label: "Timelines and Delivery" },
    { id: 5, label: "Security and Compliances", },
    { id: 5, label: "Support and Maintenance", },
  ]

  const [toggle, setToggle] = useState(false);
  const [openMainSection, setOpenMainSection] = useState("Our Experience and Expertise");

  const handdleToggle = () => {
    setToggle(!toggle);
  }
  const handleMainSection = (item) => {
    setOpenMainSection(item)
  }
  return (
    <>
      <div className='2xl:mx-24 xl:mx-20 lg:mx-20 md:mx-20 sm:mx-10 mx-5 lg:mt-20 mt-24'>
        <div className='grid grid-cols-1 lg:grid-cols-1 my-10  justify-center text-center'>
          <div className='bg-gradient-animated lg:mx-[90px]'>
            <h2 className='font-bold lg:text-3xl text-xl ml-1 text-[#41b5ee]'>WHY CHOOSE EASYCAMPUS?</h2>
            <h3 className='lg:text-5xl text-lg lg:font-light pt-1 pb-1'>Trusted by varrious stakeholders Pan India</h3>
            <p className='py-1 lg:text-xl font-light'>EasyCampus is a trusted partner for numerous stakeholders throughout India, providing reliable and innovative solutions that meet diverse needs.</p>
          </div>
        </div>
      </div>
      <div className=" 2xl:mx-24 xl:mx-20 lg:mx-20 md:mx-20 sm:mx-10 mx-5 relative">
        {/* Sidebar */}
        <div className='grid grid-cols-1 lg:grid-cols-12'>
          <div className={`bg-light  flex-col justify-between duration-300 col-span-1 lg:col-span-3 ${toggle ? "w-[80px]" : "w-[100%]"} flex-shrink-0`}>
            <div className="flex flex-col items-start relative bg-gray-50 border-y-4 shadow-lg rounded-lg border-[#1e336a]">
              {/* Menu Items */}
              {menuItems.map((item, index) => (
                <div
                  key={index}
                  className={`flex z-10 selectMenuButton cursor-pointer w-[100%] overflow-hidden whitespace-nowrap ${toggle ? "hidden" : ""} ${openMainSection === item.label ? "active border-b-2" : "border-b-2"}`}>
                  <div
                    onClick={() => handleMainSection(item.label)}
                    className="flex py-3 px-3 w-full">
                    <div>
                      {!toggle && (
                        <span className={`text-base  ${openMainSection === item.label ? "font-semibold" : "font-medium "}`}>
                          {item.label}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Main Content */}
          <div className="flex-grow lg:pl-10 pl-5 pb-3 pr-14 bg-white flex-basis-0 col-span-1 lg:col-span-9 relative">
            <div className="absolute top-1/2 left-10 transform -translate-y-1/2 h-full w-[0.2px] ml-3 hidden lg:block">
              <div className='bg-[#1e336a] h-[60%]'></div>
            </div>
            {openMainSection === "Our Experience and Expertise" && (
              <div className='lg:ml-24 mt-10 lg:mt-[-6px]'>
                <h4 className=' font-normal text-[#FF5349] '>OUR EXPERIENCE AND EXPERTISE</h4>
                <p className='pt-1 lg:text-4xl lg:leading-[45px] tracking-wide text-2xl font-extrabold'>Driving innovation and creating exceptional products.</p>
                <div className='lg:text-[36px] text-2xl ml-10 mt-4 leading-[55px] tracking-[1px] font-extralight'>
                  <blockquote>
                  With extensive experience and deep expertise, easycampus drives innovation and develops exceptional products that stand out in the market.
              </blockquote>
                </div>
              </div>
            )}
            {openMainSection === "Our Methodology" && (
              <div className='lg:ml-24 mt-10 lg:mt-[-6px]'>
                <div className='lg:text-xl text-xl font-normal text-[#FF5349]'>OUR METHODOLOGY</div>
                <p className='pt-1 lg:text-4xl lg:leading-[45px] tracking-wide text-2xl font-extrabold'>It helps to ensure that our platform<br/> is in-line with our stakeholder’s need and<br/> are more likely to be successful.</p>
                <div className='lg:text-[36px] text-2xl ml-10 mt-4 leading-[55px] tracking-[1px] font-extralight'>
                  <blockquote>We utilize a robust and efficient methodology that ensures smooth and successful implementations, tailored to meet the specific requirements of each client.</blockquote>
                </div>
              </div>
            )}
            {openMainSection === "Flexibility and Scalability" && (
              <div className='lg:ml-24 mt-10 lg:mt-[-6px]'>
                <div className='lg:text-xl text-xl font-normal text-[#FF5349]'>FLEXIBILITY AND SCALABILITY</div>
                <p className='pt-1 lg:text-4xl lg:leading-[45px] tracking-wide text-2xl font-extrabold'>Having capacity to adapt the changes and to full fill the meet of increased demand.</p>
                <div className='lg:text-[36px] text-2xl ml-10 mt-4 leading-[55px] tracking-[1px] font-extralight'>
                  <blockquote>
                  Our solutions are designed with flexibility and scalability in mind, allowing them to grow and adapt alongside your organization’s evolving needs.
                  </blockquote>
                </div>
              </div>
            )}
            {openMainSection === "Cost and Budget considerations" && (
              <div className='lg:ml-24 mt-10 lg:mt-[-6px] '>
                <div className='lg:text-xl text-xl font-normal text-[#FF5349]'>COST AND BUDGET CONSIDERATION</div>
                <p className='pt-1 lg:text-4xl lg:leading-[45px] tracking-wide text-2xl font-extrabold'>It ensures the long-term value and align us with our goals and financial capabilities.</p>
                <div className='lg:text-[36px] text-2xl ml-10 mt-4 leading-[55px] tracking-[1px] font-extralight'>
                  <blockquote>
                  We offer cost-effective solutions that maximize value without compromising on quality, ensuring your budget is optimally utilized.
                    </blockquote>
                </div>
              </div>
            )}
            {openMainSection === "Timelines and Delivery" && (
              <div className='lg:ml-24 mt-10 lg:mt-[-6px]'>
                <div className='lg:text-xl text-xl font-normal text-[#FF5349]'>TIMELINES AND DELIVERY</div>
                <p className='pt-1 lg:text-4xl lg:leading-[45px] tracking-wide text-2xl font-extrabold '>It helps to plan the work and stay on<br/> schedule to meet the deadlines also crucial<br/> for reputation and integrity.</p>
                <div className='lg:text-[36px] text-2xl ml-10 mt-4 font-[200]  tracking-wide'>
                  <blockquote>
                  We are committed to delivering projects on time, adhering to strict timelines while maintaining high standards of quality and efficiency.  </blockquote>
                </div>
              </div>
            )}
            {openMainSection === "Security and Compliances" && (
              <div className='lg:ml-24 mt-10 lg:mt-[-6px]'>
                <div className='lg:text-xl text-xl font-normal text-[#FF5349]'>SECURITY AND COMPLIANCES</div>
                <p className='pt-1 lg:text-4xl lg:leading-[45px] tracking-wide text-2xl font-extrabold'>To gain the trust of stakeholders it is<br/> important to protect the sensitive information from potential threats.</p>
                <div className='lg:text-[36px] text-2xl ml-10 mt-4 leading-[55px] tracking-[1px] font-extralight'>
                  <blockquote>
                  EasyCampus places a strong emphasis on security and compliance, ensuring all our solutions meet the latest industry standards and regulatory requirements.
                  </blockquote>
                </div>
              </div>
            )}
            {openMainSection === "Support and Maintenance" && (
              <div className='lg:ml-24 mt-10 lg:mt-[-6px]'>
                <div className='lg:text-xl text-xl font-normal text-[#FF5349]'>SUPPORT AND MAINTENANCE</div>
                <p className='pt-1 lg:text-4xl lg:leading-[45px] tracking-wide text-2xl font-extrabold'>It is an important function for the smooth operation eventually maximise the benefits to the end-users.</p>
                <div className='lg:text-[36px] text-2xl ml-10 mt-4 leading-[55px] tracking-[1px] font-extralight'>
                  <blockquote>
                  We provide comprehensive support and maintenance services, ensuring your systems run smoothly and any issues are promptly addressed.
                  </blockquote>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Achivements