import React from 'react';
import Image from 'next/image';
import layer3 from "/public/images/layer3.png";
import mapbackground from "/public/images/mapbackground.png";
import { motion } from 'framer-motion';

const Map = () => {
  return (
    <>
    <section className='2xl:mx-40 xl:mx-32 lg:mx-20 md:mx-20 sm:mx-10 mx-5 mt-14'>
     <div className='bg-[#fff] text-center relative'> 
        <motion.h2
         initial={{y:100, opacity:0}}
         whileInView={{y:0, opacity:1}}
         transition={{delay:0.6, 
          y:{type:"spring", 
         stiffness:60}, 
         opacity:{duration:1},
         ease:"easeIn", duration:1}}
        className='p-5 lg:text-[40px] text-[22px] tracking-wider'>Targeting more than <span className=' font-semibold'>50,000 students enrollment this year</span></motion.h2>
        <motion.div
         initial={{ opacity: 0, scale: 0.5 }}
         whileInView={{ opacity: 1, scale: 1 }}
         transition={{ duration: 0.5 }}
        className='p-8 flex justify-center items-center relative'>
         <Image src={mapbackground} alt='virtual campus recruitment ' className='h-auto w-full lg:h-[100vh]'/>
         <div className='absolute grid grid-cols-4 gap-28'>
         </div>
        </motion.div>
        <div className='absolute -bottom-10 ml-44 map_img_rotate hidden lg:block'>
            <Image src={layer3} alt='automated hiring system' className='w-52 h-full'/>
        </div>
     </div>   
    </section>
    </>
  )
}

export default Map