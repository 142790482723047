import React from 'react';
import Image from 'next/image';
import { motion } from 'framer-motion';
import layer1 from "../../public/images/layer1.png";
import tabandphone from "/public/images/laptopdesktop.png";
import desktopandlaptop from "/public/images/desktoplaptop1.webp";
import usersvg from "../../public/svg/increse-productivity.svg";
import decreasetimeline from "../../public/svg/decrease-timeline.svg";
import geographicalbarrier from "../../public/svg/geographical-barrier.svg"

const carddata = [
  { card: 1, src: usersvg, alt: 'virtual hiring solutions', text: 'Corporate App.', content: "lorem Ipsum content", backgroundColor: "#ffe3e1" },
  { card: 2, src: decreasetimeline, alt: 'virtual hiring solutions', text: 'College/University App.', content: "lorem Ipsum content", backgroundColor: "#daebff" },
  { card: 3, src: geographicalbarrier, alt: 'virtual hiring solutions', text: 'Student App', content: "lorem Ipsum content", backgroundColor: "#fee6d9" },
];

const Itservice = () => {
  return (
    <>
      <section>
        <div className='2xl:mx-24 xl:mx-20 lg:mx-20 md:mx-20 sm:mx-10 mx-5'>
          <motion.h2
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              x: {
                type: "spring",
                stiffness: 60
              },
              opacity: { duration: 1 },
              ease: "easeIn", duration: 1
            }}
            className='text-[#41b8ea] text-center lg:text-3xl text-xl font-bold'>CROSS PLATFORM ACCESSIBILITY
          </motion.h2>
          <motion.p
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              x: {
                type: "spring",
                stiffness: 60
              },
              opacity: { duration: 1 },
              ease: "easeIn", duration: 1
            }}
            className='text-center text-[#1e336a]  lg:leading-[45px] font-light lg:text-4xl text-base pt-1 lg:px-44' >
            Now Drive Campus From Anywhere As Appliction Available Both On Web & Mobile</motion.p>
          <div className='grid grid-cols-1  lg:grid-cols-12 xl:grid-cols-12 2xl:grid-cols-12 relative '>
            <div
              className="col-span-1 lg:col-span-6 xl:col-span-6 2xl:col-span-5 relative flex justify-center">
              <motion.span
                initial={{ y: -100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                  delay: 0.8,
                  y: {
                    type: "spring",
                    stiffness: 60
                  },
                  opacity: { duration: 1 },
                  ease: "easeIn", duration: 1
                }}
                className='flex'>
                <Image
                  src={desktopandlaptop}
                  alt="campus placement software"
                  className="h-auto w-[500px]"
                /></motion.span>
            </div>
            <div className='col-span-1 lg:col-span-6 xl:col-span-6 2xl:col-span-6 relative flex justify-center'>
              <div className='relative z-10'>
                <h4 className=' font-normal lg:text-3xl text-[#FF5349] mt-6'>WEB APPLICATION</h4>
                <motion.p
                  initial={{ y: -100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    delay: 0.6,
                    y: {
                      type: "spring",
                      stiffness: 60
                    },
                    opacity: { duration: 1 },
                    ease: "easeIn", duration: 1
                  }}
                  className='lg:text-4xl mt-1 lg:leading-[55px] leading-7 tracking-[1px] font-extralight'>Our user-friendly interface negates the necessity for<span className='font-semibold'> maintenance and hard drive storage.</span> It consistently receives software and security updates automatically, ensuring robust protection against security threats.
                </motion.p>
                <motion.button
                  initial={{ x: 100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{
                    delay: 0.2,
                    x: {
                      type: "spring",
                      stiffness: 60
                    },
                    opacity: { duration: 1 },
                    ease: "easeIn", duration: 1
                  }}
                  disabled
                  className='ml-[2px] py-3 px-5 mt-3 relative z-10 rounded text-[#51a9ff] border-2 hover:border-[#1e336a] hover:text-[#1e336a] border-[#51a9ff] '>
                  Read More
                </motion.button>
              </div>
            </div>
            <div className='absolute top-[80px] right-0 left-0 w-full h-full'>
              <Image src={layer1} alt='virtual hiring' className=' relativew-full h-full' />
            </div>
          </div>
          <div className='grid grid-cols-1  lg:grid-cols-12 xl:grid-cols-12 2xl:grid-cols-12 relative mt-10'>
            <div className=' col-span-1 lg:col-span-6 xl:col-span-6 2xl:col-span-6 relative flex justify-center'>
              <div className=' relative z-10'>
                <h4 className='font-normal lg:text-3xl text-[#FF5349]'>MOBILE APPLICATION</h4>{" "}
                <motion.p
                  initial={{ y: -100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    delay: 0.6,
                    y: {
                      type: "spring",
                      stiffness: 60
                    },
                    opacity: { duration: 1 },
                    ease: "easeIn", duration: 1
                  }}
                  className='lg:text-4xl mt-3 lg:leading-[55px] leading-7 tracking-[1px] font-extralight'>Mobile apps are designed for<span className='font-semibold'> efficiency and ease</span>  of use, enabling you to perform necessary tasks from anywhere if you can't access your computer or laptop. Each stakeholder has access to their own dedicated version of the mobile app.
                </motion.p>
                <motion.button
                  initial={{ x: 100, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  transition={{
                    delay: 0.2,
                    x: {
                      type: "spring",
                      stiffness: 60
                    },
                    opacity: { duration: 1 },
                    ease: "easeIn", duration: 1
                  }}
                  disabled
                  className='ml-[12px] py-3 px-5 mt-3 relative z-10 rounded text-[#51a9ff] border-2 hover:border-[#1e336a] hover:text-[#1e336a] border-[#51a9ff] '>
                  Read More
                </motion.button>
              </div>
            </div>
            <div
              className="col-span-1 lg:col-span-6 xl:col-span-6 2xl:col-span-6 relative flex justify-center">
              <motion.span
                initial={{ y: -100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{
                  delay: 0.8,
                  y: {
                    type: "spring",
                    stiffness: 60
                  },
                  opacity: { duration: 1 },
                  ease: "easeIn", duration: 1
                }}
                className='flex items-center'>
                <Image
                  src={tabandphone}
                  alt="campus hiring solutions"
                  width={750}
                  height={450}
                  className='h-[425px] w-[675px]'/>
              </motion.span>
            </div>
            <div className='absolute top-[80px] right-0 left-0 w-full h-full'>
              <Image src={layer1} alt='campus hiring automation' className=' relativew-full h-full' />
            </div>
          </div>
          <div className='flex justify-center items-center'>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-14 mt-10'>
              {carddata.map(item => (
                <div key={item.card} className="accomplished-cards1 cursor-pointer">
                  <div className="cardcircle1" style={{ backgroundColor: item.backgroundColor }}></div>
                  <Image src={item.src} alt={item.alt} width="60" height="60" className='z-10 h-[80px] w-[80px]' />
                  <h5
                    className="accomplished-content1 lg:p-5 p-2 lg:mt-24 mt-[94px] text-center lg:text-[17px]"
                    style={{
                      transition:'background-color 0.2s ease',
                    }}>
                    <span className='font-bold lg:text-lg text-sm'>{item.boldtext}</span>{" "}{item.text}
                  </h5>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Itservice