import React, { useState } from 'react';
import Image from 'next/image';
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { motion } from 'framer-motion';
import laptophome from "../../public/images/laptophome.webp";
import homeinnerbaner from "../../public/images/homeiner1.webp";
import homeinnerbg from "../../public/images/homeinnerbg2.png";
import DemoForm from './demoform';
import Contactuscoman from '../contactus/contactuscommon';


const Homeslider = () => {
  const [addForm, setAddForm] = useState("");
  const [demoForm, setDemoForm] = useState(false);

  const handleDemoForm = () => {
    setAddForm("add");
    setDemoForm(true);
  };

  return (
    <>
      <section>
        {demoForm && (
          <DemoForm
            isOpen={demoForm}
            onClose={() => setDemoForm(false)}
          />
        )}
        <div className='homepage-background-overlay h-[100vh] lg:h-[125vh]'>
          <Swiper
            initialSlide={0}
            speed={2000}
            loop={true}
            roundLengths={true}
            mousewheel={true}
            grabCursor={true}
            slidesPerView={1}
            spaceBetween={50}
            centeredSlides={true}

            autoplay={{
              delay: 7000,
              pauseOnMouseEnter: true,
              // disableOnInteraction: false,
              // autoplayDisableOnInteraction:true,
            }}
            pagination={{
              // el: '.swiper-pagination',
              clickable: true,
            }}
            // navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <div className='w-full'>
              <SwiperSlide>
                <div className='2xl:mx-24  xl:mx-[84px] lg:mx-[84px] md:mx-20 sm:mx-10 mx-5 h-[105vh] ml-8'>
                  <div className='grid grid-cols-1 lg:grid-cols-12 py-20 '>
                    <div className='col-span-7'> 
                      <div className='text-[#1e336a] flex flex-col text-start mt-10'>
                        <div className='text-xl font-bold text-[#FF5349]'> #WeDriveEasyCampus</div>
                        <h1 className='text lg:text-[30px] text-[27px] font-light text-[#41b8ea] lg:mt-14 mt-6'>Help To Automate</h1>
                        <h3 className='font-bold py-1 lg:text-[33px] text-[30px] lg:pr-32 '>Facilitating end-to-end campus drives with complete automation, free from geographical barriers.</h3>
                        <p className='py-1 lg:text-[30px] text-[25px] tracking-[0.04em] leading-[43px]'>Enter a new era of campus recruitment with easycampus and witness the future of data-driven decision-making.</p> 
                        <div className='text-[#fff]'>
                           <button  className='px-1 py-1 rounded-full bg-gradient-to-br from-[#51A9FF] to-[#1e336a] hover:bg-slate-800 button01 mt-4'
                            onClick={() => handleDemoForm()}>
                            <span className='block
                           bg-[#1e336a] hover:bg-[#51A9FF] rounded-full px-5 py-2'>Click Here For Demo</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='col-span-5 hidden lg:block'>
                      <div>
                        <Image
                          src={homeinnerbaner} alt='campus placement' className='w-full h-52 lg:h-auto mt-10' />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='2xl:mx-24  xl:mx-[84px] lg:mx-[84px] md:mx-20 sm:mx-10 mx-5 ml-8'>
                  <div className='grid grid-cols-1 lg:grid-cols-12 h-[100vh] py-20'>
                    <div className='col-span-7 flex flex-col text-start text-[#1e336a] lg:mt-10 mt-8'>
                      <div className=' text-xl font-bold  text-[#FF5349]'> #WeDriveEasyCampus</div>
                      <h1 className='text lg:text-[30px] text-[27px] font-light text-[#41b8ea] lg:mt-14 mt-6'>Unleash Your Dream,</h1>
                      <h3 className='font-bold py-1 lg:text-[33px] text-[30px]'>Please find your first job on your finger tip</h3>
                      <p className='py-1 lg:text-[30px] text-[25px] tracking-[0.04em] leading-[43px]'>Step into a new era of campus drive with the easycampus and become the witness to the<br/> future of data-driven decisions.</p>
                      <div className='text-[#fff]'><br />
                      <button  className='px-1 py-1 rounded-full bg-gradient-to-br from-[#51A9FF] to-[#1e336a] hover:bg-slate-800 button01'
                            onClick={() => handleDemoForm()}>
                            <span className='block
                           bg-[#1e336a] hover:bg-[#51A9FF] rounded-full px-5 py-2'>Click Here For Demo</span>
                          </button>
                      </div>
                    </div>
                    <div className='col-span-5  lg:mr-20 hidden  lg:block mt-10'>
                        <Image src={laptophome} alt='automate campus placement' className='w-full h-52 lg:h-auto' />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='mt-20 lg:mx-44 lg:h-[100vh] lg:scale-95'>
                  <div className='relative'>
                    <motion.div
                      initial={{ opacity: 0, scale: 0.5 }}
                      whileInView={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.5 }} >
                      <Image
                        src={homeinnerbg} alt='campus placement automation' className='w-[100%] lg:w-[70%] h-[80vh] lg:rounded-3xl' />
                    </motion.div>
                    <div className='flex justify-center items-center text-center bg-[#fff] shadow-lg lg:rounded-3xl p-10 absolute top-0 lg:top-10 right-0 h-full lg:w-[60%] sm:m-10 lg:m-0'>
                      <div className='text-[#000]'>
                        <div className='text-xl font-bold text-[#FF5349] flex left'> #WeDriveEasyCampus</div><br /><br />
                        <h1 className='text text-3xl font-light text-[#41b8ea] flex left'>Preferred Partner For,</h1>
                        <h3 className='font-bold flex text-left py-1 text-[33px]'>Talent-Centric Organazations Pan India</h3>
                        <p className='py-1 text-[30px] tracking-[0.04em] leading-[43px] flex text-left'>Start your jounary now to enhance time-to-value and reduce costs.</p>
                        <div className='text-[#fff] flex left'>
                          <button  className='px-1 py-1 rounded-full bg-gradient-to-br from-[#51A9FF] to-[#1e336a] hover:bg-slate-800 mt-3 button01'
                            onClick={() => handleDemoForm()}>
                            <span className='block
                           bg-[#1e336a] hover:bg-[#51A9FF] rounded-full px-5 py-2'>Click Here For Demo</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
        </div>
        <Contactuscoman />
      </section>
    </>
  )
}

export default Homeslider;
