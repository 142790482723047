import React from 'react';
import Image from 'next/image';
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import logo1 from "/public/images/logo1.png";
import logo2 from "/public/images/logo2.png";
import logo3 from "/public/images/logo3.png";
import logo4 from "/public/images/logo4.png";
import logo5 from "/public/images/logo5.png";
import logo6 from "/public/images/logo6.png";

const Logosection = () => {
  return (
    <>
    <section>
    <div  className='my-20 2xl:mx-24 xl:mx-20 lg:mx-20 md:mx-20 sm:mx-10 mx-5'>
    <Swiper
        initialSlide={2}
        speed={1000}
        loop={true}
        roundLengths={true}
        mousewheel={true}
        grabCursor={true}
        spaceBetween={50}
        // slidesPerView={4}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1025: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper">
         {logos.map((logo, index) => (
              <SwiperSlide key={index}>
                <div className='logosection'>
                  <Image src={logo.src} width={100} height={100} alt={logo.alt} className='w-40 h-14 p-4' />
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
       </div> 
    </section>
    </>
  )
}

const logos = [
  { src: logo1, alt: 'recruitment automation software' },
  { src: logo2, alt: 'campus recruitment process' },
  { src: logo3, alt: 'campus hiring strategy' },
  { src: logo4, alt: 'campus hiring process' },
  { src: logo5, alt: 'online campus recruitment' },
  { src: logo6, alt: 'campus placement portal' },
];

export default Logosection