import React from 'react';
import Link from 'next/link';
import { motion } from "framer-motion";

const LatestarticalHeading = () => {
    return (
        <>
            <div className="grid grid-cols-1 lg:grid-cols-12 2xl:mx-24 xl:mx-20 lg:mx-20 md:mx-20 sm:mx-10 mx-5 mt-24">
                <div className="col-span-1 lg:col-span-4">
                    <motion.h2
                        initial={{ y: -100, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{
                            delay: 0.6,
                            y: {
                                type: "spring",
                                stiffness: 60
                            },
                            opacity: { duration: 1 },
                            ease: "easeIn", duration: 1
                        }}
                        className='font-normal text-[26px] text-[#41b8ea] '>Check Out Our, <p className="text-[#1e336a] font-[900] text-4xl tracking-wide mt-1">Latest Blogs and <br />Articles</p></motion.h2>
                </div>
                <div className="col-span-1 lg:col-span-8">
                    <motion.p
                        initial={{ y: -100, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{
                            delay: 0.6,
                            y: {
                                type: "spring",
                                stiffness: 60
                            },
                            opacity: { duration: 1 },
                            ease: "easeIn", duration: 1
                        }}
                        className="pb-2 text-[24px] tracking-[0.05em]">We publish <span className=' font'>well-researched articles and blogs,</span> rather than just narrative content,  adds significant value by covering the latest trends and updates relevant to our stakeholders. <span className=' font-[900] tracking-[0.01em] mt'>THIS APPROCH HELPS IN ESTABLISHING AUTHORITY AND SOLIDIFYING OUR REPUTATION.</span></motion.p>
                    <div className="flex justify-end">
                      <Link href="/blogs"><motion.button
                            initial={{ y: 100, opacity: 0 }}
                            whileInView={{ y: 0, opacity: 1 }}
                            transition={{
                                delay: 0.6,
                                y: {
                                    type: "spring",
                                    stiffness: 60
                                },
                                opacity: { duration: 1 },
                                ease: "easeIn", duration: 1
                            }}
                            className="py-2 px-8 my-2 bg-[#1e336a] text-[#fff] rounded-full buttonshadow">View All</motion.button></Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LatestarticalHeading;