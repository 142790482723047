
import React from "react";
import { Inter } from 'next/font/google'
import Departments from "@/components/departments/departments";
import Itservice from "@/components/itservices/itservice";
import Aboutushomepage from "@/components/aboutus/aboutushomepage";
import Latestarticles from "@/components/latestarticle/latestarticles";
import Logosection from "@/components/logosection/logosection";
import Map from "@/components/map/map";
import Achivements from "@/components/achivements/achivements";
import Homeslider from "@/components/homepageslider/homeslider";
import CampusDrive from "@/components/placementservices";
import CookieConsent from "@/components/cookies/cookieconsent";

const inter = Inter({
  subsets: ['latin'],
  variable: '--font-inter',
})

export default function Homeindex() {

  return (
    <>
    <main className={`${inter.variable}  flex min-h-screen flex-col w-[100%]`}>
      {/* <CookieConsent/> */}
     <div className="w-[100%]">
      <Homeslider/>
      <Achivements/>
      <CampusDrive/>
      <Itservice/>
      <Departments/>
      {/* <Aboutushomepage/> */}
      <Latestarticles/>
      {/* <Logosection/> */}
      <Map/>
     </div>
    </main>
    </>
   
  );
}