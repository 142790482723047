import React, { useEffect, useState } from 'react'
import Image from "next/image";
import Link from 'next/link';
import logoeasycampus from "../../public/images/logo-easycampus.png";
import latestcardimg1 from "../../public/images/blog1.jpg";
import latestcardimg2 from "/public/images/blog2.jpg";
import { motion } from "framer-motion";
import { truncateFiftyText, truncateText } from '@/utils/constant';

const latestarticaltitle = ({ count }) => {
  const [currentDate, setCurrentDate] = useState('');
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const month = now.toLocaleString('default', { month: 'short' });
      const day = now.getDate();
      const year = now.getFullYear();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();

      setCurrentDate(`${month} ${day}, ${year}`);
      setCurrentTime(`${hours}:${minutes}:${seconds}`);
    };

    const intervalId = setInterval(updateDateTime, 1000);
    return () => clearInterval(intervalId);
  }, []);

  let style;
  switch (count) {
    case 2:
      style = ""
      break;
    case 3:
      style = "2xl:mx-24 xl:mx-20 lg:mx-20 md:mx-20 sm:mx-10 mx-5"
      break;

    default:
      break;
  }

  const data = [
    {
      bannerImg: latestcardimg1,
      image: logoeasycampus,
      name: "Easy Campus",
      dateTime: currentDate,
      time: "At 9PM",
      heading: "HR in the 21st Century: New Challenges, New Solutions",
      title: " HR in the twenty-first century must be adaptable and adaptable to changing work habits. HR departments in the new ...",
      time1: "-2min Read",
      url: "/blogs/readmoreblogs/hr-in-the-21st-century-new-challenges-new-solutions"
      // url: "/blogs"
    },
    {
      bannerImg: latestcardimg2,
      image: logoeasycampus,
      name: "Easy Campus",
      dateTime: currentDate,
      time: "At 10PM",
      heading: " How to Improve Your HR Management Skills?",
      title: " Every business faces difficulties in finding and keeping qualified staff. This procedure relies heavily on effective...",
      time1: "-5min Read",
      url: "/blogs/readmoreblogs/how-to-improve-your-hr-management-skills"
    },
    {
      bannerImg: latestcardimg1,
      image: logoeasycampus,
      name: "Easy Campus",
      dateTime: currentDate,
      time: "at 7PM",
      heading: "The Ultimate Guide to Recruit the Best Employees in Your Organization",
      title: " The employment of the proper people is critical to any company's success...",
      time1: "-3min Read",
      url: "blogs/readmoreblogs/the-ultimate-guide-to-recruit-the-best-employees-in-your-organization"
    }
  ]

  return (
    <>
      <div className={`flex justify-center  mt-8  ${style}`}>
        <div className="grid gap-6 grid-cols-1 lg:grid-cols-12">
          {data.map((item,index) => (
            <div key={index} className="col-span-1 lg:col-span-4">
            <Link href={item?.url}>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}>
                  <Image src={item?.bannerImg} alt="virtual campus hiring" className="w-full rounded-t-[30px]" />
                </motion.div>
                <div className="bg-[#fff] px-8 py-8 rounded-b-[36px] border-b-4 shadow-lg border-b-[#1e336a]">
                  <motion.div
                    initial={{ y: -100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{
                      delay: 0.4,
                      y: { type: "spring", stiffness: 60 },
                      opacity: { duration: 1 },
                      ease: "easeIn",
                      duration: 1
                    }}
                    className="mb-3"
                  >
                    <div className="flex justify-start items-start">
                      <Image src={item?.image} alt="campus recruitment" className="w-12 h-12" />
                      <div className="text-left mt-2 ml-[4px]">
                        <h5 className='text-[14px] font-normal ml-[2px]'>{item?.name}</h5>
                        <h5 className='text-[14px] font-normal'>{item?.dateTime}{" "}<span>{item?.time}</span></h5>
                      </div>
                    </div>
                  </motion.div>
                  <motion.h4
                    initial={{ y: -100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{
                      delay: 0.6,
                      y: {
                        type: "spring",
                        stiffness: 60
                      },
                      opacity: { duration: 1 },
                      ease: "easeIn", duration: 1
                    }}
                    className={`${count == 2 ? "mb-4 truncate  tracking-tight text-[#1e336a] text-xl" : "mb-4 truncate tracking-tight text-[#1e336a]"}`}>
                    {item?.heading}
                  </motion.h4>
                  <motion.p
                    initial={{ y: -100, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{
                      delay: 0.8,
                      y: {
                        type: "spring",
                        stiffness: 60
                      },
                      opacity: { duration: 1 },
                      ease: "easeIn", duration: 1
                    }}
                    className={`${count == 2 ? "text-gray-500 font-medium text-base my-2" : "text-black font-medium my-2"}`}>
                    {count === 2 ? (
                      <>
                        {item.title?.length > 50
                          ? `${(
                            truncateFiftyText(item.title)
                          )}...`
                          : (item.title) || " "}
                       
                      </>
                    ) : (
                      <>
                        {item.title?.length > 70
                          ? `${(
                            truncateText(item.title)
                          )}...`
                          : (item.title) || " "}

                      </>
                    )}
                  </motion.p>
                  <div>
                    <div className="flex items-center">
                      <div className=''>{item?.time1}</div>
                      <div className="ml-auto">
                        <button className='cardbtnread btn-1 '>Read More</button>
                      </div>
                    </div>
                  </div>
                </div>
            </Link>
            </div>
          ))}
        </div>
      </div>

    </>
  )
}

export default latestarticaltitle