import React from 'react';
import { motion } from 'framer-motion';
import stakrholderbg2 from "../../public/images/glimpseimg2.webp"
import stakrholderbg1 from "../../public/images/glimpseimg5.jpg"
import stakrholderbg3 from "../../public/images/glimpseimg4.jpg"
import Image from 'next/image';

const departmentsData = [
  {
    title: "Corporate",
    subtitle: "Structured Hiring Approach",
    description: "Our structured hiring approach offers the chance to streamline costs, reduce hiring time, and concentrate solely on critical tasks in the recruitment process. This method eliminates geographical barriers, helping to achieve the organization's vision efficiently and effectively",
    buttonColor: "#007bff",
    bgClass: stakrholderbg2
  },
  {
    title: "College/Universities",
    subtitle: "Latest Trend of Campus Drive",
    description: "Our all-in-one solution offers a comprehensive view of the entire campus placement lifecycle. It includes data management, agile methodology, the latest updates & requirements, providing everything you need for successful campus recruitment drive.",
    buttonColor: "#6f42c1",
    bgClass: stakrholderbg1
  },
  {
    title: "Students",
    subtitle: "Transparent and unbiased Opportunity",
    description: "This innovative approach to career development ensures clear and timely communication, providing equal opportunities for all. The transparent and unbiased process helps students meet their employment expectations and carrier positioning effectively.",
    buttonColor: "#fd7e14",
    bgClass: stakrholderbg3
  }
];

const Departments = () => {
  return (
    <>
      <section>
        <div className='mt-10 2xl:mx-24 xl:mx-20 lg:mx-20 md:mx-20 sm:mx-10 mx-5'>
          <motion.h2
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              y: { type: "spring", stiffness: 60 },
              opacity: { duration: 0.2 },
              ease: "easeIn",
              duration: 1
            }}
            className='text-[#1e336a] text-center lg:mb-10 mb-5 lg:text-[46px] text-[25px]'>Glimpse of Our <span className='font-black'>Stakeholder's</span>
          </motion.h2>
          <div className="parallax-container parallax-container2">
            {departmentsData.map((department, index) => (
              <div key={index} className="card p-5">
                <div className={`card__content relative z-10 ${department.bgClass}`}>
                  <Image src={department.bgClass} alt="campus placement" width={100} height={100}
                   className="filter absolute h-[585px] w-[405px] -z-0 mt-5"/>
                  <h4 className='px-[30px] z-10' style={{ color: department.buttonColor }}>{department.title}</h4>
                  <h3 className=' px-[30px] z-10'>{department.subtitle}</h3>
                  <p className='mx-[30px] z-10'>{department.description}</p>
                  <button  className='read-more00 button01 z-10 mx-[30px]' style={{ backgroundColor: department.buttonColor }}>Read More</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Departments;
