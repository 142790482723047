import React from 'react';
import Image from 'next/image';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import aboutusimg from "../../public/images/aboutusimg_two.webp";

const Aboutushomepage = () => {
  return (
    <>
    <section>
  <h2 className=' text-center text-[#1e336a] pt-9'>Our Happy <span className='font-black'>client's</span></h2>
    <div className='my-7 relative homeaboutusbg'>
      <Swiper
        spaceBetween={50}
        centeredSlides={true}
        initialSlide={2}
        speed={2000}
        loop={true}
        roundLengths={true}
        mousewheel={true}
        grabCursor={true}
        slidesPerView={1}
        autoplay={{
          delay: 5500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
       {aboutusContent.map((slide, index) => (
        <SwiperSlide key={index}>
        <div className='text-[#fff] text-center p-9'>
          <div className='grid grid-cols-1 lg:grid-cols-12'>
       <div className='flex flex-col items-center justify-center col-span-4'>
        <Image
        src={aboutusimg}
        alt='campus hiring'
        className='w-64 h-64 object-cover rounded-full'
        />
        <h5 className='pt-5'>{slide.name}</h5>
        <p>Global Markets Engineer</p>
       </div>
           <div className='flex flex-col text-start mt-5 col-span-8'>
            <h3 className='py-5'>Smart Work</h3>
            <h4 className='pb-5'>Flexible cloud strategies. Choose the one that`s right for you. </h4>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas quisquam est optio voluptate vel harum unde porro culpa accusantium? Voluptatum harum tempora corrupti illo ad, esse quae excepturi tenetur eligendi?</p>
          </div>
          </div>
        </div>
           </SwiperSlide>
         ))}
         </Swiper>
    </div>
    </section>
    </>
  )
}

const aboutusContent = [
  {
    name: 'Hawikns'
  },
  {
    name: 'Cheery'
  },
  {
    name: 'Shinu'
  }
]

export default Aboutushomepage