import React, { useState } from 'react';
import Image from 'next/image';
import desktop1img from "../../public/images/desktopimg1.webp";
import desktop2img from "../../public/images/desktopimg2.webp";
import desktop3img from "../../public/images/desktopimg3.webp";
import desktop4img from "../../public/images/desktopimg4.webp";
import desktop5img from "../../public/images/desktopimg5.webp";

const CampusDrive = () => {
  const [activeButton, setActiveButton] = useState(1);
  const handleButtonClick = (number) => {
    setActiveButton(number);
  };

  return (
    <>
       <div className='placementsolutions_overlay lg:my-12 my-10'>
        <div className='2xl:mx-24 xl:mx-20 lg:mx-20 md:mx-20 sm:mx-10 mx-5 text-center text-[#fff] pt-14 lg:pb-0 pb-5'>
          <h2 className='text-center font-bold lg:text-4xl text-2xl text-[#fff] campusdriveheading pb-2'>ONE STOP CAMPUS PLACEMENT SOLUTION</h2>
          <h2 className='text-center font-light lg:text-4xl text-lg py-2 text-[#41b8ea]'>Comprehensive tech-enabled platform is designed to manage the diverse phases of the campus placement and recruitment supply chain seamlessly.</h2>
          <div className="tab_container">
            <div className="selectSection">
              <button
                type="button"
                data-number="1"
                className={activeButton === 1 ? 'active' : ''}
                onClick={() => handleButtonClick(1)}>
                Platform
              </button>
              <button
                type="button"
                data-number="2"
                className={activeButton === 2 ? 'active' : ''}
                onClick={() => handleButtonClick(2)}>
                Automation
              </button>
              <button
                type="button"
                data-number="3"
                className={activeButton === 3 ? 'active' : ''}
                onClick={() => handleButtonClick(3)}>
                Outreach
              </button>
              <button
                type="button"
                data-number="4"
                className={activeButton === 4 ? 'active' : ''}
                onClick={() => handleButtonClick(4)}>
                Screening
              </button>
              <button
                type="button"
                data-number="5"
                className={activeButton === 5 ? 'active' : ''}
                onClick={() => handleButtonClick(5)}>
                Invitations
              </button>
            </div>
            <div className="contentSection">
              {contentData.map((content) => (
                <div
                  key={content.number}
                  className="content"
                  style={{ display: activeButton === content.number ? 'block' : 'none' }}>
                  <div className='grid grid-cols-1 lg:grid-cols-2 gap-14'>
                    <div className='text-start'>
                      <h4 className={`font-normal text-`} style={{ color: content.headingColor }}>{content.heading}</h4>
                      <h4 className='lg:text-4xl  text-2xl py-1 lg:leading-[43px] tracking-wide font-extrabold'>{content.subheading}</h4>
                      <p className='font-normal text-xl'>{content.paragraph}</p>
                      <button disabled className='px-1 py-1 bg-gradient-to-br from-[#51A9FF] to-[#1e346a] hover:bg-slate-800 mt-3'>
                        <span className='block bg-[#1e346a97] hover:bg-[#51A9FF] px-5 py-2'>Read More</span>
                      </button>
                    </div>
                    <div className='lg:-mt-5 -mt-16'>
                      <Image src={content.image} alt='resume' className='w-[90%] h-[95%]' />
                    </div>
                  </div>
                </div>
              ))}
            </div>
           </div>
          <div>
         </div>
        </div>
       </div>
    </>
  )
}


const contentData = [
  {
    number: 1,
    heading: 'CENTRALIZED PLATFORM',
    headingColor: '#cfde0b',
    subheading: 'It integrates automation, outreach, screening, and invitations into a centralized system, enhancing productivity and ensuring smooth operations.',
    paragraph: 'This platform provides numerous benefits, offering clear vision and strategic direction, along with enhanced control and coordination of resources and activities. It streamlines processes and equips businesses with unique and valuable capabilities.',
    image: desktop1img,
  },
  {
    number: 2,
    heading: 'AUTOMATED PLACEMENT PROCESS',
    headingColor: '#10d639',
    subheading: 'Streamlining campus drives with automation',
    paragraph: 'Automation enhances productivity and ensures high accuracy. Despite the inherent complexity of campus drives, our solution is designed to save time, improve efficiency, and accelerate the entire process. This results in reduced operating costs while maintaining the highest quality standards.',
    image: desktop2img,
  },
  {
    number: 3,
    heading: 'EFFECTIVE OUTREACH',
    headingColor: '#d4073b',
    subheading: 'Overcoming geographical restrictions in campus hiring',
    paragraph: 'Restrictions can significantly impact hiring results. Limiting campus recruitment to local communities due to geographical constraints can dramatically affect your outcomes. However, with an advanced technological platform like EasyCampus, these barriers are eliminated. EasyCampus enables you to connect with a broader pool of candidates, ensuring that geographical limitations no longer hinder your recruitment process.',
    image: desktop3img,
  }
  ,
  {
    number: 4,
    heading: 'ONLINE PROFILE SCREENING',
    headingColor: '#ea8c0d',
    subheading: 'The importance of initial candidate screening',
    paragraph: 'An initial screening process is crucial to identify the best fit for a specific role. This step ensures that candidates possess the necessary skills and qualifications, allowing recruiters to focus on the most promising individuals. Effective screening enhances both time and cost efficiency, enabling organizations to respond quickly and make informed hiring decisions.',
    image: desktop5img,
  },
  {
    number: 5,
    heading: 'ONE-GO INVITATIONS',
    headingColor: '#ff51f3',
    subheading: 'Optimize your hiring event with targeted shortlisting',
    paragraph: 'Set the tone for a successful hiring event and eliminate non-productive activities with our platform. Gain access to a comprehensive database and utilize dynamic filters to customize your search results. This allows you to efficiently shortlist candidates and find exactly what you need, ensuring a streamlined and effective recruitment process.',
    image: desktop4img,
  },
];

export default CampusDrive