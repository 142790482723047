import React from "react";
import LatestarticalHeading from "./latestarticalheading";
import LatestarticalTitle from "./latestarticaltitle";

const Latestarticles = () => {

  return (
    <>
      <section>
       <LatestarticalHeading/>
       <LatestarticalTitle count={3}/>
      </section>
    </>
  )
}

export default Latestarticles